import { useEffect, useState } from 'react';
import moment from 'moment';
import { CSSTransition } from 'react-transition-group';

import { Disclosure } from './Disclosure';
import { Timeline } from './Timeline';

import { photos } from './photos';

const mapDate = (date) => {
  if (date === '2025-01-01') {
    return 'Unknown date';
  } else if (date === '2021-02-02') {
    return 'Circa 2021';
  } else if (date === '2019-01-15') {
    return 'January 2019';
  } else if (date.length === 4) {
    return `Circa ${date}`;
  }
  return moment(date).format('MMMM D, YYYY');
}


function App() {
  const [showPhotos, setShowPhotos] = useState(false);
  const [numPhotosLoaded, setNumPhotosLoaded] = useState(0);
  const [mappedPhotos, setMappedPhotos] = useState([]);
  const [photosToRender, setPhotosToRender] = useState([]);
  const [interval, setTheInterval] = useState();
  const [photosLastRetrievedAt, setPhotosLastRetrievedAt] = useState();

  useEffect(() => {
    const initialMappedPhotos = photos
    .map(photo => ({ ...photo, date: photo.date ? photo.date : '2025-01-01' }))
    .sort((a, b) => {
      return moment.utc(a.date).diff(moment.utc(b.date))
    })
    .map(photo => ({
      ...photo,
      date: mapDate(photo.date),
      location: photo.location ? photo.location : `Unknown location`,
    }));
    setMappedPhotos(initialMappedPhotos);
    setPhotosToRender(initialMappedPhotos.slice(0, 10));
    setPhotosLastRetrievedAt(moment());

    const { innerWidth } = window;
    if (innerWidth >= 577) {
      window.alert("For a better experience, please visit this site on a mobile phone, it wasn't designed for large screens :'(");
    }
  }, []);

  const increaseNumPhotosLoaded = () => {
    setNumPhotosLoaded(numPhotosLoaded + 1)
  }

  useEffect(() => {
    if (photosToRender.length === 0) return;
    if (numPhotosLoaded % 6 === 0) {
      setPhotosToRender(mappedPhotos.slice(0, photosToRender.length + 10));
      if ((photosToRender.length + 10) >= photos.length) {
        clearInterval(interval);
      }
    }
  }, [numPhotosLoaded]);

  useEffect(() => {
    if (showPhotos) {
      const loadInterval = setInterval(() => {
        if (moment().diff(photosLastRetrievedAt) > 2800) {
          setNumPhotosLoaded(6);
          setPhotosLastRetrievedAt(moment());
        }
      }, 3000);
      setTheInterval(loadInterval);
    }
  }, [showPhotos]);

  return (
    <div className="App">
      {!showPhotos && <Disclosure setShowPhotos={setShowPhotos} />}
      <CSSTransition
        in={showPhotos}
        timeout={30000}
        classNames="fade"
        unmountOnExit
        onEnter={() => setShowPhotos(true)}
        onExited={() => setShowPhotos(false)}
      >
        <Timeline photos={photosToRender} photoOnLoad={increaseNumPhotosLoaded} />
      </CSSTransition>
    </div>
  );
}

export default App;
