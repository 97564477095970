import './index.css';

export const Timeline = ({ photos, photoOnLoad }) => (
    <div className="timeline-container">
        <div className='time-capsule'>
            <div className='time-capsule-text-container'>
                <p className='time-capsule-text'>Jump into the time capsule</p>
                <p className='time-capsule-text-arrow'>↓</p>
            </div>
        </div>
        {photos.map(({ date, title, location, photo, id }, i) => (
            <div className={`timeline-row-${i%4}`} key={id}>
                <div className='timeline-photo-header'>
                    <p className={`timeline-photo-text-${i%4}`}>
                        {location}
                    </p>
                    <p className={`timeline-photo-text-${i%4}`}>
                        {date}
                    </p>
                </div>
                <img className='timeline-photo' src={photo} onLoad={photoOnLoad} />
                <p className={`timeline-photo-text-${i%4}-photo-title`}>{title}</p>
                {i !== 0 && <div className='blur'></div>}
            </div>
        ))}
    </div>
);