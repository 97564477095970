
import './index.css';
import lock from './lock.png';

export const Disclosure = ({ setShowPhotos }) => {
    const preventOrphan = (s) => s.replace(/\s([^\s<]+)\s*$/,'\u00A0$1');
    const disclaimerText = 'YOU MUST BE 18 YEARS OR OLDER TO ENTER';
    
    return (
        <div className="disclosure-container">
            <div className='disc-header'>
                <h1 className="disclosure-header">
                    <span className="only">Only</span>
                    <span>Thans</span>
                </h1>
                <img src={lock} className='lock'></img>
            </div>
            <h1 className="disclosure-title">ADULTS ONLY</h1>
            <h4 className="disclosure-body">{preventOrphan(disclaimerText)}</h4>
            <button className="disclosure-button" onClick={() => setShowPhotos(true)}>CONTINUE</button>
        </div>
    );
};
