import p0 from './Jerry.JPG';
import p1 from './M_03-27-2021.JPG';
import p2 from './M_07-02-2022.jpeg'
import p3 from './M_07-02-2022_1.jpeg';
import p4 from './M_UNK.JPG';
import p5 from './M_UNK_1.JPG';
import p6 from './T_01-15-2018.JPG';
import p7 from './T_03-17-2019.JPG';
import p8 from './T_03-17-2019_1.JPG';
import p9 from './T_05-12-2019.JPG';
import p10 from './T_07-02-2022.jpeg';
import p11 from './T_07-05-2019.JPG';
import p12 from './T_07-28-2018.JPG';
import p13 from './T_07-29-2018.JPG';
import p14 from './T_10-17-2021.JPG';
import p15 from './T_10-26-2019.JPG';
import p16 from './T_10-26-2019_1.JPG';
import p17 from './T_11-12-2018.JPG';
import p18 from './T_11-23-2019.JPG';
import p19 from './T_11-29-2019.JPG';
import p20 from './T_12-4-2020.jpeg';
import p21 from './T_12-4-2020_1.jpeg';
import p22 from './T_12-08-2018.JPG';
import p23 from './T_12-10-2021.jpeg';
import p24 from './T_12-31-2018.JPG';
import p25 from './T_BABY.JPG';
import p26 from './T_UNK_1.JPG';
import p27 from './T_UNK_2.JPG';
import p28 from './T_UNK_3.JPG';
import p32 from './T_UNK_7.JPG';
import p34 from './T_UNK_9.JPG';
import p35 from './T_UNK_10.JPG';
import p36 from './T_UNK_11.JPG';
import p37 from './TJ_05-15-2021.JPG';
import p38 from './TM_01-30-2021.JPEG';
import p39 from './TM_05-12-2019.JPG';
import p40 from './TM_05-28-2021.JPG';
import p41 from './TM_07-02-2022.jpeg';
import p42 from './TM_07-02-2022_1.jpeg';
import p43 from './TM_07-02-2022_2.jpeg'
import p44 from './TM_12-31-2019.JPG';
import p45 from './TM_UNK.JPG';
import p46 from './TM_UNK_1.JPG';
import p47 from './0.JPG';
import p48 from './1.jpeg';
import p49 from './2.jpeg';
import p50 from './3.jpg';
import p51 from './4.png';
import p52 from './5.jpg';
import p53 from './6.jpg';
import p54 from './7.jpeg';
import p55 from './8.jpeg';
import p56 from './9.jpg';
import p57 from './10.png';
import p58 from './11.png';
import p59 from './12.png';
import p60 from './13.jpeg';
import p61 from './14.jpeg';
import p63 from './16.jpg';
import p64 from './17.png';
import p65 from './18.png';
import p66 from './19.png';
import p67 from './20.JPG';
import p68 from './21.jpg';
import p69 from './22.jpeg';
import p70 from './23.jpeg';
import p71 from './24.png';
import p72 from './25.png';
import p73 from './26.png';
import p74 from './27.png';
import p75 from './28.png';
import p76 from './29.png';
import p78 from './31.png';
import p80 from './33.png';
import p81 from './34.png';
import p82 from './35.png';
import p84 from './37.png';
import p85 from './38.png';
import p86 from './39.png';
import p87 from './40.png';
import p88 from './41.png';
import p89 from './42.png';
import p90 from './43.png';
import p91 from './44.png';
import p92 from './45.PNG';
import p93 from './46.jpeg';

export const photos = [
    {
        title: "Jerry the Good Boy: Part 1",
        date: "2021-02-02",
        location: "Hayes Valley",
        photo: p0,
        id: 84,
    },
    {
        title: "Quarter Century Club: Part 1",
        date: "2021-03-27",
        location: "Conservatory of Flowers",
        photo: p1,
        id: 1,
    },
    {
        title: "SF Takes SD: Part 1",
        date: "2022-07-02",
        location: "Petco Park",
        photo: p2,
        id: 2,
    },
    {
        title: "SF Takes SD: Part 2",
        date: "2022-07-02",
        location: "Petco Park",
        photo: p3,
        id: 3,
    },
    {
        title: "",
        date: "2022-02-25",
        location: "Rickshaw Stop",
        photo: p4,
        id: 4,
    },
    {
        title: "Thanimal Turns 29: Part 1",
        date: "2020-07-26",
        location: "Hayes Valley",
        photo: p27,
        id: 5,
    },
    {
        title: "Thanimal Turns 29: Part 2",
        date: "2020-07-26",
        location: "Hayes Valley",
        photo: p5,
        id: 6,
    },
    {
        title: "Shred the Gnar",
        date: "2018-01-15",
        location: 'Heavenly Ski Resort',
        photo: p6,
        id: 7,
    },
    {
        title: "F*ck Your Song Request",
        location: "Hayes Valley",
        date: "2019-03-17",
        photo: p7,
        id: 8,
    },
    {
        title: "Thanimal B2B Kevster",
        location: "Hayes Valley",
        date: "2019-03-17",
        photo: p8,
        id: 9,
    },
    {
        title: "A Love is Born: Part 1",
        date: "2019-05-12",
        location: "LIB Music Festival",
        photo: p9,
        id: 10,
    },
    {
        title: "SF Takes SD: Part 3",
        date: "2022-07-02",
        location: "Petco Park",
        photo: p10,
        id: 11,
    },
    {
        title: "Hoesome Boiz",
        date: "2019-07-05",
        location: "Petco Park",
        photo: p11,
        id: 12,
    },
    {
        title: "Sibling Love",
        date: "2018-07-28",
        location: "Tahoe",
        photo: p12,
        id: 13,
    },
    {
        title: "Family",
        date: "2018-07-29",
        location: "Tahoe",
        photo: p13,
        id: 14,
    },
    {
        title: "",
        date: "2021-10-16",
        photo: p14,
        id: 15,
    },
    {
        title: "Where's Waldo?: Part 1",
        date: "2019-10-26",
        location: "San Francisco Bay",
        photo: p15,
        id: 16,
    },
    {
        title: "Where's Waldo?: Part 2",
        date: "2019-10-26",
        location: "San Francisco Bay",
        photo: p16,
        id: 17,
    },
    {
        title: "Niner's Record Breaking 4-12 Season",
        date: "2018-11-12",
        location: "Levi's Stadium",
        photo: p17,
        id: 18,
    },
    {
        title: "",
        date: "2019-11-23",
        location: "Khao San Road",
        photo: p18,
        id: 19,
    },
    {
        title: "",
        date: "2019-11-29",
        location: "Marina Bay Sands",
        photo: p19,
        id: 20,
    },
    {
        title: "",
        date: "2020-12-04",
        location: "525 Gough",
        photo: p20,
        id: 21,
    },
    {
        title: "",
        date: "2020-12-04",
        location: "525 Gough",
        photo: p21,
        id: 22,
    },
    {
        title: "Merry Christmas With the Fam",
        date: "2018-12-08",
        location: "Hayes Valley",
        photo: p22,
        id: 23,
    },
    {
        title: "Donde Esta La Fiesta?: Part 6",
        date: "2021-12-10",
        location: "Tulum",
        photo: p23,
        id: 24,
    },
    {
        title: "Happy New Year!",
        date: "2018-12-31",
        location: "San Francisco",
        photo: p24,
        id: 25,
    },
    {
        title: "The Birth of the ABB: Part 1",
        date: "1991-07-26",
        location: "Myanmar (Burma)",
        photo: p25,
        id: 26,
    },
    {
        title: "",
        date: "2022-02-25",
        location: "Rickshaw Stop",
        photo: p26,
        id: 27,
    },
    {
        title: "Thanimal Turns 29: Part 3",
        date: "2020-07-26",
        photo: p28,
        location: "Hayes Valley",
        id: 28,
    },
    {
        title: "",
        date: "",
        photo: p32,
        id: 29,
    },
    {
        title: "The Birth of the ABB: Part 2",
        date: "2014-02-16",
        location: "LED Anniversary SD",
        photo: p34,
        id: 30,
    },
    {
        title: "Going hella dumb with my friends",
        date: "2020-01-25",
        location: "Downtown SF",
        photo: p35,
        id: 31,
    },
    {
        title: "Free the Nip",
        date: "2019-07-13",
        location: "525 Gough",
        photo: p36,
        id: 32,
    },
    {
        title: "Cat Daddy",
        date: "2021-05-15",
        location: "Hayes Valley",
        photo: p37,
        id: 33,
    },
    {
        title: "",
        date: "2021-01-30",
        location: "Tahoe",
        photo: p38,
        id: 34,
    },
    {
        title: "A Love is Born: Part 2",
        date: "2019-05-12",
        location: "LIB Music Festival",
        photo: p39,
        id: 35,
    },
    {
        title: "The Best Ships Are Friendships: Part 1",
        date: "2021-05-28",
        location: "Shasta Lake",
        photo: p40,
        id: 36,
    },
    {
        title: "SF Takes SD: Part 4",
        date: "2022-07-02",
        location: "Petco Park",
        photo: p41,
        id: 37,
    },
    {
        title: "SF Takes SD: Part 5",
        date: "2022-07-02",
        location: "Petco Park",
        photo: p42,
        id: 38,
    },
    {
        title: "SF Takes SD: Part 6",
        date: "2022-07-02",
        location: "Petco Park",
        photo: p43,
        id: 39,
    },
    {
        title: "A Gatsby New Year: Part 1",
        date: "2019-12-31",
        location: "525 Gough",
        photo: p44,
        id: 40,
    },
    {
        title: "Thanimal Turns 29: Part 4",
        date: "2020-07-26",
        location: "Hayes Valley",
        photo: p45,
        id: 41,
    },
    {
        title: "Thanimal Turns 29: Part 5",
        date: "2020-07-26",
        location: "Hayes Valley",
        photo: p46,
        id: 42,
    },
    {
        title: "Donde Esta La Fiesta?: Part 1",
        date: "2021-12-09",
        location: "Tulum",
        photo: p47,
        id: 43,
    },
    {
        title: "The Best Ships Are Friendships: Part 2",
        date: "2021-05-28",
        location: "Shasta Lake",
        photo: p48,
        id: 44,
    },
    {
        title: "The Best Ships Are Friendships: Part 3",
        date: "2021-05-28",
        location: "Shasta Lake",
        photo: p49,
        id: 45,
    },
    {
        title: "Donde Esta La Fiesta?: Part 2",
        date: "2021-12-09",
        location: "Tulum",
        photo: p50,
        id: 46,
    },
    {
        title: "Quarter Century Club: Part 2",
        date: "2021-03-27",
        location: "Conservatory of Flowers",
        photo: p51,
        id: 47,
    },
    {
        title: "Cast Away 2",
        date: "2019-07-27",
        location: "Carmel-by-the-Sea",
        photo: p52,
        id: 48,
    },
    {
        title: "Donde Esta La Fiesta?: Part 3",
        date: "2021-12-09",
        location: "Tulum",
        photo: p53,
        id: 49,
    },
    {
        title: "The Best Ships Are Friendships: Part 4",
        date: "2021-05-28",
        location: "Shasta Lake",
        photo: p54,
        id: 50,
    },
    {
        title: "Girls Just Wanna Have Fun",
        date: "2022-07-30",
        location: "Tahoe",
        photo: p55,
        id: 51,
    },
    {
        title: "Donde Esta La Fiesta?: Part 4",
        date: "2021-12-09",
        location: "Tulum",
        photo: p56,
        id: 52,
    },
    {
        title: "13 Going on 30: Part 1",
        date: "2021-07-24",
        location: "Saint Helena",
        photo: p57,
        id: 53,
    },
    {
        title: "CRSSD With the Crew",
        date: "2019-09-29",
        location: "Chula Vista",
        photo: p58,
        id: 54,
    },
    {
        title: "",
        date: "2021-08-20",
        location: "Cow Hollow",
        photo: p59,
        id: 55,
    },
    {
        title: "The Best Ships Are Friendships: Part 5",
        date: "2021-05-28",
        location: "Shasta Lake",
        photo: p60,
        id: 56,
    },
    {
        title: "13 Going on 30: Part 2",
        date: "2021-07-24",
        location: "Saint Helena",
        photo: p61,
        id: 57,
    },
    {
        title: "Donde Esta La Fiesta?: Part 5",
        date: "2021-12-09",
        location: "Tulum",
        photo: p63,
        id: 58,
    },
    {
        title: "New Years in San Diego: Part 2",
        date: "2021-01-01",
        location: "La Jolla",
        photo: p64,
        id: 59,
    },
    {
        title: "Las Vegas State of Mind",
        date: "2019-08-16",
        location: "Wynn Las Vegas",
        photo: p65,
        id: 60,
    },
    {
        title: "I'd Rather Be At Coachella",
        date: "2019-04-14",
        location: "Coachella",
        photo: p66,
        id: 61,
    },
    {
        title: "13 Going on 30: Part 3",
        date: "2021-07-24",
        location: "Saint Helena",
        photo: p67,
        id: 62,
    },
    {
        title: "Peek a Boo",
        date: "2019-01-20",
        location: "",
        photo: p68,
        id: 63,
    },
    {
        title: "P.S. I Love You",
        date: "2019-11-02",
        location: "Dolores Park",
        photo: p69,
        id: 64,
    },
    {
        title: "A Gatsby New Year: Part 2",
        date: "2020-01-01",
        location: "Embarcadero",
        photo: p70,
        id: 65,
    },
    {
        title: "You're a Wizard Harry!",
        date: "2021-10-31",
        location: "Golden Gate Park",
        photo: p71,
        id: 66,
    },
    {
        title: "Pizza pizza, get your pizza!",
        date: "2019-03-17",
        location: "Chicago",
        photo: p72,
        id: 67,
    },
    {
        title: "The Juice: Vol. I",
        date: "2021-11-09",
        location: "The Regency Ballroom",
        photo: p73,
        id: 68,
    },
    {
        title: "",
        date: "2021-11-06",
        location: "YOLO",
        photo: p74,
        id: 69,
    },
    {
        title: "New Years in San Diego: Part 1",
        date: "2020-12-30",
        location: "La Jolla",
        photo: p75,
        id: 70,
    },
    {
        title: "",
        date: "2021-08-27",
        location: "Cow Hollow",
        photo: p76,
        id: 71,
    },
    {
        title: "",
        date: "2022-02-25",
        location: "Rickshaw Stop",
        photo: p78,
        id: 72,
    },
    {
        title: "New Years in San Diego: Part 3",
        date: "2021-01-05",
        location: "San Diego",
        photo: p80,
        id: 73,
    },
    {
        title: "Life's a Blur",
        date: "2019-10-31",
        location: "San Jose",
        photo: p81,
        id: 74,
    },
    {
        title: "One Big Happy Family",
        date: "2021-06-26",
        location: "Hayes Valley",
        photo: p82,
        id: 75,
    },
    {
        title: "Jerry the Good Boy: Part 2",
        date: "2021-10-23",
        location: "Hayes Valley",
        photo: p84,
        id: 76,
    },
    {
        title: "Quarter Century Club: Part 3",
        date: "2021-03-27",
        location: "Conservatory of Flowers",
        photo: p85,
        id: 77,
    },
    {
        title: "Partners in Crime",
        date: "2018-08-12",
        location: "Outside Lands",
        photo: p86,
        id: 78,
    },
    {
        title: "",
        date: "2019-01-15",
        location: "Downtown Los Gatos",
        photo: p87,
        id: 79,
    },
    {
        title: "",
        date: "2021-08-20",
        location: "Pacific Heights",
        photo: p88,
        id: 80,
    },
    {
        title: "Jorja and Jhené",
        date: "2022-04-30",
        location: "Sol Blume",
        photo: p89,
        id: 81,
    },
    {
        title: "The Juice: Vol. II",
        date: "2021-11-09",
        location: "The Regency Ballroom",
        photo: p90,
        id: 82,
    },
    {
        title: "What We Do in the Shadows",
        date: "2020-11-01",
        location: "Fort Mason",
        photo: p91,
        id: 83,
    },
    {
        title: "A Star is Born: Part 1",
        date: "1996-04-04",
        location: "Saratoga",
        photo: p92,
        id: 92,
    },
    {
        title: "A Star is Born: Part 2",
        date: "2006",
        location: "Saratoga",
        photo: p93,
        id: 93,
    },
];